<template>
    <div class="ANN" id="ANN">
        <div class="container">
            <div class="row">
                <div class="ANN-slide">
                    <div class="swiper-container">
                        <swiper :options="swiperOption">
                            <swiper-slide v-for="(list, index) in hamtragchBaiguullaga"  :key="index" >
                                <div class="clients-item">
                                    <Tooltip placement="top" :content="list.ner" style="text-transform: uppercase" :disabled="disabled">
                                        <a target="_blank" :href="list.link"><img :src="image_url_mbua + list.logo"></a>
                                    </Tooltip>
                                </div>
                            </swiper-slide>
                            <div class="swiper-pagination" slot="pagination"></div>
                        </swiper>
                        <div class="swiper-button-prev-1" slot="button-prev">
                            <i class="ivu-icon ivu-icon-ios-arrow-dropleft-circle"></i>
                        </div>
                        <div class="swiper-button-next-1" slot="button-next">
                            <i class="ivu-icon ivu-icon-ios-arrow-dropright-circle"></i>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>


import {GET_HAMTRAGCH_BAIGUULLAGUUD} from "../../graphql/queries";
import {mapGetters} from 'vuex';
import {IMAGE_URL} from "../../graphql/queries";

export default {
    name: 'home',
    data() {
        return {
            disabled: false,
            hamtragchBaiguullaga: [],
            image_url_mbua: IMAGE_URL,
            swiperOption: {
                slidesPerView: 5,
                spaceBetween: 10,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next-1',
                    prevEl: '.swiper-button-prev-1'
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 5,
                        spaceBetween: 10
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 10
                    },
                    640: {
                        slidesPerView: 3,
                        spaceBetween: 10
                    },
                    375: {
                        slidesPerView: 2,
                        spaceBetween: 10
                    },
                    320: {
                        slidesPerView: 2,
                        spaceBetween: 2
                    }
                }
            },
        }
    },
    computed: {
        ...mapGetters([
            'languages',
            'language',
            // ...
        ])
    },
    methods: {
        getCategory() {
            this.$apollo.query({query: GET_HAMTRAGCH_BAIGUULLAGUUD}).then(({data}) => {
                this.hamtragchBaiguullaga = data.ds_co_operation_new_from_table;
            })
        },
    },
    mounted() {
        this.getCategory();
    }
}
</script>
<style>
</style>
